body {
	background: #f4f6fa;
	font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	color: #444;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 2px solid #bfbfbf;
    border-radius: 50%;
    border-top: 2px solid #2d84bb;
    width: 50px;
    height: 50px;
    align-items: center;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: inline-block;
  }
}
.badge-success {
  background-color: $success-color;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 15px;
}

.badge-warning {
  background-color: $warning-color;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.btn-w-8 {
	max-width: 8rem !important;
	min-width: 8rem !important;
}

.btn-w-4 {
  max-width: 3rem !important;
  min-width: 3rem !important;
}

.breadcrumb {
  background-color: transparent;
  font-size: 18px !important;
}

.breadcrumb-item a:hover {
  text-decoration: none;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  line-height: 25px;
}
.wrapper-checkbox label::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
