// Colors
$base-color: #619dd0;
$danger-color: #d67068;
$success-color: #75c675;
$warning-color: #f2b55d;
$red-color: #c3220b;

// Screen width
$mainWidth: 1024px;

// Background Color
$bgBlack: #202538;
$bgLime: #82bc50;
$bgLaurel: #f4fafa;
$bgOffWhite: #f6f6f6;
$bgGray: #b5b5b5;
$bgWhite: #ffffff;
$bgIndia: #3f6623;
$bgDisable: #c1c1c1;
$bgTableHeader: #efefef;
$gbOptionTableHeader: #f2f2f2;
$bgAegean: #1e355c;

// Text color
$textLime: #5da31f;
$textWhite: #ffffff;
$textShadow: #191919;
$textOfWhite: #f6f6f6;
$textGray: #6f6f6f;
$textPewter: #b5b5b5;
$textBlack: #202538;
$textGreen: #1187bc;
$textCloud: #484848;
$textDisable: #c1c1c1;
$textRed: #c3210c;

// Border color
$borderLime: #5c9b25;
$borderGrey: #adadad;

// Space
$spaceDefault: 4px;

//Font family
$Roboto: 'Roboto', sans-serif;
$AlteHaasGrotesk: AlteHaasGrotesk, sans-serif;
$NirmalaUI: NirmalaUI, sans-serif;
